@use '@angular/material' as mat;
@use '../palettes' as palettes;

$border-radius: 8px;
$primary-600: mat.get-color-from-palette(
  palettes.$nc-comp-primary-palette,
  600
);
$accent-400: mat.get-color-from-palette(palettes.$nc-comp-accent-palette, 400);
$accent-600: mat.get-color-from-palette(palettes.$nc-comp-accent-palette, 600);
$warn-600: mat.get-color-from-palette(palettes.$nc-comp-warn-palette, 600);

@mixin nc-formfield-theme() {
  mat-form-field .mdc-text-field--outlined {
    --mdc-outlined-text-field-focus-outline-width: 1px;
    --mdc-outlined-text-field-container-shape: #{$border-radius};
    --mdc-outlined-text-field-outline-color: #{$accent-400};
    --mdc-outlined-text-field-label-text-color: #{$accent-600};
    --mdc-outlined-text-field-hover-outline-color: #{$primary-600};
    --mdc-outlined-text-field-caret-color: #{$primary-600};
    --mdc-outlined-text-field-focus-outline-color: #{$primary-600};
    --mdc-outlined-text-field-focus-label-text-color: #{$primary-600};

    // Selected element text color in <select>
    --mat-select-enabled-trigger-text-color: var(
      --nc-core-base-black-opacity-80
    );
    --mat-select-enabled-arrow-color: #{$accent-600};
    --mat-select-trigger-text-line-height: 150%;
    --mat-select-placeholder-text-color: #{$accent-600};

    // Disabled
    --mdc-outlined-text-field-disabled-label-text-color: var(
      --nc-core-base-black-opacity-30
    );
    --mat-select-disabled-trigger-text-color: var(
      --nc-core-base-black-opacity-30
    );
    --mdc-outlined-text-field-disabled-outline-color: var(
      --nc-core-base-black-opacity-15
    );
    --mat-select-disabled-arrow-color: var(--nc-core-base-black-opacity-30);
    --mat-select-focused-arrow-color: var(--nc-core-base-black-opacity-30);

    // Error state
    --mdc-outlined-text-field-error-caret-color: #{$warn-600};
    --mdc-outlined-text-field-error-focus-label-text-color: #{$warn-600};
    --mdc-outlined-text-field-error-label-text-color: #{$warn-600};
    --mdc-outlined-text-field-error-focus-outline-color: #{$warn-600};
    --mdc-outlined-text-field-error-hover-outline-color: #{$warn-600};
    --mdc-outlined-text-field-error-outline-color: #{$warn-600};
    --mat-select-invalid-arrow-color: #{$warn-600};
  }

  .mat-mdc-form-field-icon-prefix > nc-icon {
    padding-left: 12px;
    padding-right: 4px;
  }

  .mat-mdc-form-field-icon-suffix > nc-icon {
    padding-right: 12px;
    padding-left: 4px;
  }

  mat-form-field.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper,
  mat-form-field.mat-mdc-form-field-has-icon-suffix
    .mat-mdc-text-field-wrapper {
    --mat-mdc-form-field-label-offset-x: -16px;
  }
}
