@use '@angular/material' as mat;
@use '../palettes' as palettes;

$border-radius: 8px;
$primary-800: mat.get-color-from-palette(
  palettes.$nc-comp-primary-palette,
  800
);
$accent-50: mat.get-color-from-palette(palettes.$nc-comp-accent-palette, 50);
$accent-100: mat.get-color-from-palette(palettes.$nc-comp-accent-palette, 100);

@mixin nc-select-theme() {
  mat-option,
  .mat-mdc-option {
    // Selected option text color
    --mat-option-selected-state-label-text-color: #{$primary-800};

    // Selected option background
    --mat-option-selected-state-layer-color: #{$accent-50};

    // Hover + focus background
    --mat-option-hover-state-layer-color: #{$accent-100};
    --mat-option-focus-state-layer-color: #{$accent-100};
    --mat-option-label-text-color: var(--nc-core-base-black-opacity-80);
    --mat-option-label-text-weight: 400;
  }

  mat-option.mdc-list-item {
    --mat-option-selected-state-label-text-color: var(
      --nc-core-base-black-opacity-80
    );
  }

  div.mat-mdc-select-panel {
    border-radius: #{$border-radius} !important;
  }

  .cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
    border-top-left-radius: #{$border-radius} !important;
    border-top-right-radius: #{$border-radius} !important;
  }

  // Checkmark color selected option
  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
  .mat-primary
    .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: #{$primary-800} !important;
  }

  // Redefine hover styles to display hover on active item as well (default is only on non-active items)
  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: var(--mat-option-hover-state-layer-color) !important;
  }
}
