@use 'sass:map';
@use '../typography' as typo;

.cdk-overlay-pane:has(mat-option) {
  @apply flex flex-col rounded-lg shadow-lg;
}

div.mat-mdc-autocomplete-panel {
  @apply overflow-y-scroll rounded-none py-0 shadow-none #{!important};

  &.mat-mdc-autocomplete-visible {
    @apply max-h-[576px] min-h-80;
  }

  mat-option {
    --mat-option-label-text-size: #{map.get(typo.$nc-body-2, 'font-size')};

    @apply block min-h-10 p-3;

    &.mdc-list-item--disabled .mdc-list-item__primary-text {
      @apply mat-body-2 text-nc-black-opacity-60 pointer-events-auto p-4 opacity-100;
    }
  }

  mark {
    @apply text-nc-blue-800 bg-transparent font-bold;
  }
}
