@mixin nc-table-theme() {
  .mat-mdc-table {
    --mat-table-row-item-outline-color: var(--nc-core-gray-100);
    --mat-table-header-headline-color: var(--nc-core-base-black-opacity-70);
    --mat-table-row-item-label-text-color: var(--nc-core-base-black-opacity-80);

    &:not(:has(> tbody > tr.mat-mdc-no-data-row)) {
      @apply table-fixed #{!important};
    }

    thead tr {
      @apply bg-nc-gray-50;

      th {
        @apply mat-caption text-nc-black-opacity-70 border-b-0 px-2 py-3 text-left first:rounded-l-lg first:pl-5 last:rounded-r-lg last:pr-5;

        .mat-sort-header-content {
          @apply text-left;
        }
      }
    }

    tbody tr {
      td {
        @apply mat-body bg-transparent p-2 pt-4 align-top font-normal first:pl-5 last:pr-5;
      }
    }
  }
}
