@use '@angular/material' as mat;
@use '../palettes' as palettes;

$primary-300: mat.get-color-from-palette(
  palettes.$nc-comp-primary-palette,
  300
);
$primary-600: mat.get-color-from-palette(
  palettes.$nc-comp-primary-palette,
  600
);
$accent-200: mat.get-color-from-palette(palettes.$nc-comp-accent-palette, 200);
$accent-500: mat.get-color-from-palette(palettes.$nc-comp-accent-palette, 500);
$accent-800: mat.get-color-from-palette(palettes.$nc-comp-accent-palette, 800);
$warn-300: mat.get-color-from-palette(palettes.$nc-comp-warn-palette, 300);
$warn-600: mat.get-color-from-palette(palettes.$nc-comp-warn-palette, 600);

@mixin nc-radio-theme() {
  // Increase size of selected indicator
  mat-radio-button
    .mdc-radio__native-control:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    transform: scale(0.6);
  }

  // Unchecked hover/focus circle opacity
  mat-radio-button
    .mdc-radio__native-control:focus
    + .mdc-radio__background::before,
  mat-radio-button
    .mdc-radio_native-control:focus
    + .mdc-radio__background::before {
    @apply opacity-10;
  }

  // Checked hover/focus circle opacity
  mat-radio-button.mat-mdc-radio-checked
    .mdc-radio__native-control:focus
    + .mdc-radio__background::before {
    @apply opacity-15;
  }

  // Active circle opacity
  mat-radio-button.mat-mdc-radio-button
    .mdc-radio:active
    .mdc-radio__native-control:not([disabled])
    ~ .mdc-radio__background::before {
    @apply opacity-20;
  }

  // Disabled radio outer circle color
  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:disabled
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: $accent-200 !important;
  }

  // Unchecked hover/focus circle opacity
  mat-radio-button.mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__native-control:not([disabled], :focus)
    ~ .mdc-radio__background::before {
    @apply opacity-10;
  }

  // Checked hover/focus circle opacity
  mat-radio-button.mat-mdc-radio-button.mat-mdc-radio-checked
    .mdc-radio:hover
    .mdc-radio__native-control:not([disabled], :focus)
    ~ .mdc-radio__background::before {
    @apply opacity-15;
  }

  // Reset disabled radio opacity
  mat-radio-button.mat-mdc-radio-button {
    --mdc-radio-disabled-selected-icon-opacity: 1;
    --mdc-radio-disabled-unselected-icon-opacity: 1;
  }

  mat-radio-button.mat-mdc-radio-button.mat-primary,
  mat-radio-button.mat-mdc-radio-button.mat-warn {
    --mat-radio-ripple-color: var(--nc-core-base-black);
    --mdc-radio-disabled-unselected-icon-color: #{$accent-200};
    --mat-radio-disabled-label-color: #{$accent-200};
  }

  mat-radio-button.mat-mdc-radio-button.mat-primary {
    --mdc-radio-unselected-icon-color: #{$accent-500};
    --mdc-radio-unselected-hover-icon-color: #{$primary-600};
    --mdc-radio-unselected-focus-icon-color: #{$accent-800};
    --mdc-radio-unselected-pressed-icon-color: #{$primary-600};
    --mdc-radio-disabled-selected-icon-color: #{$primary-300};
  }

  mat-radio-button.mat-mdc-radio-button.mat-warn {
    --mdc-radio-unselected-icon-color: #{$warn-600};
    --mdc-radio-unselected-hover-icon-color: #{$warn-600};
    --mdc-radio-unselected-focus-icon-color: #{$warn-600};
    --mdc-radio-unselected-pressed-icon-color: #{$warn-600};
    --mdc-radio-disabled-selected-icon-color: #{$warn-300};
  }
}
