@use '../../styles/colors' as colors;

mat-button-toggle-group {
  @apply bg-nc-blue-600;

  --mat-standard-button-toggle-shape: 9999px;

  &.mat-button-toggle-group-appearance-standard {
    @apply border-0;

    mat-button-toggle {
      --mat-standard-button-toggle-background-color: #{colors.$nc-core-blue-600};
      --mat-standard-button-toggle-selected-state-background-color: #{colors.$nc-core-base-white};
      --mat-standard-button-toggle-text-color: #{colors.$nc-core-base-white-opacity-80};
      --mat-standard-button-toggle-label-text-weight: 500;
      --mat-standard-button-toggle-label-text-size: 14px;

      &.mat-button-toggle-appearance-standard {
        @apply border-nc-blue-600 rounded-full border-4;

        & + .mat-button-toggle-appearance-standard {
          --mat-standard-button-toggle-divider-color: #{colors.$nc-core-blue-600};

          border-left-width: 4px;
        }

        &:not(.mat-button-toggle-disabled) {
          &:not(.mat-button-toggle-checked) {
            &:hover {
              background-color: #{colors.$nc-core-blue-400};
            }

            &:active {
              background-color: #{colors.$nc-core-blue-300};
            }
          }

          &.cdk-focused {
            @apply border-nc-blue-900;
          }
        }

        &.mat-button-toggle-checked {
          --mat-standard-button-toggle-selected-state-text-color: #{colors.$nc-core-blue-700};

          font-weight: 700;
        }

        & .mat-button-toggle-focus-overlay {
          --mat-standard-button-toggle-hover-state-layer-opacity: 0;
        }

        &.cdk-keyboard-focused:not(.mat-button-toggle-disabled)
          .mat-button-toggle-focus-overlay {
          --mat-standard-button-toggle-focus-state-layer-opacity: 0;
        }

        .mat-button-toggle-label-content {
          @apply inline-flex items-center;

          --mat-standard-button-toggle-height: 36px;
        }

        &.mat-button-toggle-disabled {
          --mat-standard-button-toggle-disabled-state-text-color: #{colors.$nc-core-base-white-opacity-40};
          --mat-standard-button-toggle-disabled-state-background-color: #{colors.$nc-core-blue-600};

          &.mat-button-toggle-checked {
            --mat-standard-button-toggle-disabled-selected-state-text-color: #{colors.$nc-core-blue-400};
            --mat-standard-button-toggle-disabled-selected-state-background-color: #{colors.$nc-core-base-white};
          }
        }
      }
    }
  }
}
