@use 'sass:map';
@use '@angular/material' as mat;
@use '../../styles/typography' as typography;

mat-expansion-panel {
  --mat-expansion-container-shape: 12px;
  --mat-expansion-header-indicator-color: var(--nc-core-base-black-opacity-40);
  --mat-expansion-header-collapsed-state-height: 80px;
  --mat-expansion-header-expanded-state-height: 96px;
  --mat-expansion-header-hover-state-layer-color: var(--nc-core-gray-100);

  .mat-expansion-indicator {
    @apply m-3;

    &::after {
      border-color: var(--nc-core-base-black-opacity-40);
    }
  }

  .mat-expansion-panel-body {
    @apply p-4;
  }
}
