$nc-core-base-black: #000;
$nc-core-base-white: #fff;
$nc-core-base-black-opacity-05: rgb(0 0 0 / 5%);
$nc-core-base-black-opacity-10: rgb(0 0 0 / 10%);
$nc-core-base-black-opacity-15: rgb(0 0 0 / 15%);
$nc-core-base-black-opacity-20: rgb(0 0 0 / 20%);
$nc-core-base-black-opacity-25: rgb(0 0 0 / 25%);
$nc-core-base-black-opacity-30: rgb(0 0 0 / 30%);
$nc-core-base-black-opacity-35: rgb(0 0 0 / 35%);
$nc-core-base-black-opacity-40: rgb(0 0 0 / 40%);
$nc-core-base-black-opacity-50: rgb(0 0 0 / 50%);
$nc-core-base-black-opacity-60: rgb(0 0 0 / 60%);
$nc-core-base-black-opacity-70: rgb(0 0 0 / 70%);
$nc-core-base-black-opacity-75: rgb(0 0 0 / 75%);
$nc-core-base-black-opacity-80: rgb(0 0 0 / 80%);
$nc-core-base-black-opacity-90: rgb(0 0 0 / 90%);
$nc-core-base-white-opacity-05: rgb(255 255 255 / 5%);
$nc-core-base-white-opacity-10: rgb(255 255 255 / 10%);
$nc-core-base-white-opacity-15: rgb(255 255 255 / 15%);
$nc-core-base-white-opacity-20: rgb(255 255 255 / 20%);
$nc-core-base-white-opacity-25: rgb(255 255 255 / 25%);
$nc-core-base-white-opacity-30: rgb(255 255 255 / 30%);
$nc-core-base-white-opacity-35: rgb(255 255 255 / 35%);
$nc-core-base-white-opacity-40: rgb(255 255 255 / 40%);
$nc-core-base-white-opacity-50: rgb(255 255 255 / 50%);
$nc-core-base-white-opacity-60: rgb(255 255 255 / 60%);
$nc-core-base-white-opacity-70: rgb(255 255 255 / 70%);
$nc-core-base-white-opacity-75: rgb(255 255 255 / 75%);
$nc-core-base-white-opacity-80: rgb(255 255 255 / 80%);
$nc-core-base-white-opacity-90: rgb(255 255 255 / 90%);
$nc-core-blue-50: #f2fafd;
$nc-core-blue-100: #e4f5fc;
$nc-core-blue-200: #c6ebf8;
$nc-core-blue-300: #a3e0f4;
$nc-core-blue-400: #74d4f1;
$nc-core-blue-500: #1ac8ed;
$nc-core-blue-600: #17b3d4;
$nc-core-blue-700: #149bb8;
$nc-core-blue-800: #107e96;
$nc-core-blue-900: #0c596a;
$nc-core-darkblue-700: #252a44;
$nc-core-gray-50: #f7f7f5;
$nc-core-gray-100: #e2e2e1;
$nc-core-gray-200: #c6c7c4;
$nc-core-gray-300: #a2a39f;
$nc-core-gray-400: #90918d;
$nc-core-gray-500: #777774;
$nc-core-gray-600: #5e5f5c;
$nc-core-gray-700: #464745;
$nc-core-gray-800: #30302f;
$nc-core-gray-900: #1b1c1b;
$nc-core-green-50: #f7fbf3;
$nc-core-green-100: #eef8e7;
$nc-core-green-200: #dcf1cc;
$nc-core-green-300: #c8e9ac;
$nc-core-green-400: #b1e186;
$nc-core-green-500: #98d94f;
$nc-core-green-600: #88c247;
$nc-core-green-700: #76a83d;
$nc-core-green-800: #608932;
$nc-core-green-900: #446123;
$nc-core-orange-50: #fff7f2;
$nc-core-orange-100: #feeee5;
$nc-core-orange-200: #fddbc8;
$nc-core-orange-300: #fdc7a6;
$nc-core-orange-400: #fcb17b;
$nc-core-orange-500: #fb9734;
$nc-core-orange-600: #e1872f;
$nc-core-orange-700: #c27528;
$nc-core-orange-800: #9f6021;
$nc-core-orange-900: #704417;
$nc-core-red-50: #fff2f2;
$nc-core-red-100: #ffe5e4;
$nc-core-red-200: #ffc8c6;
$nc-core-red-300: #fea6a1;
$nc-core-red-400: #fe7a72;
$nc-core-red-500: #fe3107;
$nc-core-red-600: #e32c06;
$nc-core-red-700: #c52605;
$nc-core-red-800: #a11f04;
$nc-core-red-900: #721603;

:root {
  --nc-core-base-black: #{$nc-core-base-black};
  --nc-core-base-white: #{$nc-core-base-white};
  --nc-core-base-black-opacity-05: #{$nc-core-base-black-opacity-05};
  --nc-core-base-black-opacity-10: #{$nc-core-base-black-opacity-10};
  --nc-core-base-black-opacity-15: #{$nc-core-base-black-opacity-15};
  --nc-core-base-black-opacity-20: #{$nc-core-base-black-opacity-20};
  --nc-core-base-black-opacity-25: #{$nc-core-base-black-opacity-25};
  --nc-core-base-black-opacity-30: #{$nc-core-base-black-opacity-30};
  --nc-core-base-black-opacity-35: #{$nc-core-base-black-opacity-35};
  --nc-core-base-black-opacity-40: #{$nc-core-base-black-opacity-40};
  --nc-core-base-black-opacity-50: #{$nc-core-base-black-opacity-50};
  --nc-core-base-black-opacity-60: #{$nc-core-base-black-opacity-60};
  --nc-core-base-black-opacity-70: #{$nc-core-base-black-opacity-70};
  --nc-core-base-black-opacity-75: #{$nc-core-base-black-opacity-75};
  --nc-core-base-black-opacity-80: #{$nc-core-base-black-opacity-80};
  --nc-core-base-black-opacity-90: #{$nc-core-base-black-opacity-90};
  --nc-core-base-white-opacity-05: #{$nc-core-base-white-opacity-05};
  --nc-core-base-white-opacity-10: #{$nc-core-base-white-opacity-10};
  --nc-core-base-white-opacity-15: #{$nc-core-base-white-opacity-15};
  --nc-core-base-white-opacity-20: #{$nc-core-base-white-opacity-20};
  --nc-core-base-white-opacity-25: #{$nc-core-base-white-opacity-25};
  --nc-core-base-white-opacity-30: #{$nc-core-base-white-opacity-30};
  --nc-core-base-white-opacity-35: #{$nc-core-base-white-opacity-35};
  --nc-core-base-white-opacity-40: #{$nc-core-base-white-opacity-40};
  --nc-core-base-white-opacity-50: #{$nc-core-base-white-opacity-50};
  --nc-core-base-white-opacity-60: #{$nc-core-base-white-opacity-60};
  --nc-core-base-white-opacity-70: #{$nc-core-base-white-opacity-70};
  --nc-core-base-white-opacity-75: #{$nc-core-base-white-opacity-75};
  --nc-core-base-white-opacity-80: #{$nc-core-base-white-opacity-80};
  --nc-core-base-white-opacity-90: #{$nc-core-base-white-opacity-90};
  --nc-core-blue-50: #{$nc-core-blue-50};
  --nc-core-blue-100: #{$nc-core-blue-100};
  --nc-core-blue-200: #{$nc-core-blue-200};
  --nc-core-blue-300: #{$nc-core-blue-300};
  --nc-core-blue-400: #{$nc-core-blue-400};
  --nc-core-blue-500: #{$nc-core-blue-500};
  --nc-core-blue-600: #{$nc-core-blue-600};
  --nc-core-blue-700: #{$nc-core-blue-700};
  --nc-core-blue-800: #{$nc-core-blue-800};
  --nc-core-blue-900: #{$nc-core-blue-900};
  --nc-core-darkblue-700: #{$nc-core-darkblue-700};
  --nc-core-gray-50: #{$nc-core-gray-50};
  --nc-core-gray-100: #{$nc-core-gray-100};
  --nc-core-gray-200: #{$nc-core-gray-200};
  --nc-core-gray-300: #{$nc-core-gray-300};
  --nc-core-gray-400: #{$nc-core-gray-400};
  --nc-core-gray-500: #{$nc-core-gray-500};
  --nc-core-gray-600: #{$nc-core-gray-600};
  --nc-core-gray-700: #{$nc-core-gray-700};
  --nc-core-gray-800: #{$nc-core-gray-800};
  --nc-core-gray-900: #{$nc-core-gray-900};
  --nc-core-green-50: #{$nc-core-green-50};
  --nc-core-green-100: #{$nc-core-green-100};
  --nc-core-green-200: #{$nc-core-green-200};
  --nc-core-green-300: #{$nc-core-green-300};
  --nc-core-green-400: #{$nc-core-green-400};
  --nc-core-green-500: #{$nc-core-green-500};
  --nc-core-green-600: #{$nc-core-green-600};
  --nc-core-green-700: #{$nc-core-green-700};
  --nc-core-green-800: #{$nc-core-green-800};
  --nc-core-green-900: #{$nc-core-green-900};
  --nc-core-orange-50: #{$nc-core-orange-50};
  --nc-core-orange-100: #{$nc-core-orange-100};
  --nc-core-orange-200: #{$nc-core-orange-200};
  --nc-core-orange-300: #{$nc-core-orange-300};
  --nc-core-orange-400: #{$nc-core-orange-400};
  --nc-core-orange-500: #{$nc-core-orange-500};
  --nc-core-orange-600: #{$nc-core-orange-600};
  --nc-core-orange-700: #{$nc-core-orange-700};
  --nc-core-orange-800: #{$nc-core-orange-800};
  --nc-core-orange-900: #{$nc-core-orange-900};
  --nc-core-red-50: #{$nc-core-red-50};
  --nc-core-red-100: #{$nc-core-red-100};
  --nc-core-red-200: #{$nc-core-red-200};
  --nc-core-red-300: #{$nc-core-red-300};
  --nc-core-red-400: #{$nc-core-red-400};
  --nc-core-red-500: #{$nc-core-red-500};
  --nc-core-red-600: #{$nc-core-red-600};
  --nc-core-red-700: #{$nc-core-red-700};
  --nc-core-red-800: #{$nc-core-red-800};
  --nc-core-red-900: #{$nc-core-red-900};
}
