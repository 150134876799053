@use '@angular/material' as mat;
@use '../palettes' as palettes;

$primary-600: mat.get-color-from-palette(
  palettes.$nc-comp-primary-palette,
  600
);
$primary-600-contrast: mat.get-contrast-color-from-palette(
  palettes.$nc-comp-primary-palette,
  600
);
$accent-200: mat.get-color-from-palette(palettes.$nc-comp-accent-palette, 200);
$accent-500: mat.get-color-from-palette(palettes.$nc-comp-accent-palette, 500);
$warn-600: mat.get-color-from-palette(palettes.$nc-comp-warn-palette, 600);
$warn-600-contrast: mat.get-contrast-color-from-palette(
  palettes.$nc-comp-warn-palette,
  600
);

@mixin ripple-colors() {
  --mdc-checkbox-unselected-hover-state-layer-color: var(
    --nc-core-base-black-opacity-10
  );
  --mdc-checkbox-unselected-focus-state-layer-color: var(
    --nc-core-base-black-opacity-10
  );
  --mdc-checkbox-unselected-pressed-state-layer-color: var(
    --nc-core-base-black-opacity-20
  );
}

@mixin nc-checkbox-theme() {
  mat-checkbox.mat-mdc-checkbox {
    // Reset opacities to 1 because for unselected state we are using alpha values in the color
    --mdc-checkbox-unselected-hover-state-layer-opacity: 1;
    --mdc-checkbox-unselected-focus-state-layer-opacity: 1;
    --mdc-checkbox-unselected-pressed-state-layer-opacity: 1;
    --mdc-checkbox-selected-hover-state-layer-opacity: 0.15;
    --mdc-checkbox-selected-focus-state-layer-opacity: 0.15;
    --mdc-checkbox-disabled-unselected-icon-color: #{$accent-200};
    --mdc-form-field-label-text-size: 16px;
    --mdc-form-field-label-text-color: var(--nc-core-base-black-opacity-80);
  }

  mat-checkbox.mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-checkmark-color: #{$primary-600-contrast}; // Checkmark color

    // Checkbox border colors
    --mdc-checkbox-unselected-icon-color: #{$accent-500};
    --mdc-checkbox-unselected-hover-icon-color: #{$primary-600};
    --mdc-checkbox-unselected-pressed-icon-color: #{$primary-600};

    @include ripple-colors;
  }

  mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox {
    --mat-full-pseudo-checkbox-unselected-icon-color: #{$accent-500};
  }

  mat-checkbox.mat-mdc-checkbox.mat-warn {
    --mdc-checkbox-selected-checkmark-color: #{$warn-600-contrast}; // Checkmark color

    // Checkbox border colors
    --mdc-checkbox-unselected-icon-color: #{$warn-600};
    --mdc-checkbox-unselected-hover-icon-color: #{$warn-600};
    --mdc-checkbox-unselected-focus-icon-color: #{$warn-600};
    --mdc-checkbox-unselected-pressed-icon-color: #{$warn-600};

    @include ripple-colors;
  }
}
