@use '../../styles/variables' as vars;
@use '../../styles/colors' as colors;

mat-snack-bar-container {
  --mdc-snackbar-container-shape: 12px;
  --mdc-snackbar-supporting-text-color: #{colors.$dark-primary-text};

  @apply mr-6 mt-6 #{!important};
}

.mdc-snackbar__label {
  padding: 0 !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  @apply mb-3 max-w-md overflow-hidden p-0 shadow-xl #{!important};
}

.nc-snack-bar-type__success {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: #{vars.$nc-core-green-100};
  }
}

.nc-snack-bar-type__error {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: #{vars.$nc-core-red-100};
  }
}

.nc-snack-bar-type__warning {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: #{vars.$nc-core-orange-100};
  }
}

.nc-snack-bar-type__info {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: #{vars.$nc-core-blue-100};
  }
}
