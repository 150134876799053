@use 'sass:map';
@use '../../styles/colors' as colors;
@use '../../styles/typography' as typo;

.mat-mdc-dialog-surface {
  --mdc-dialog-container-shape: 12px;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  --mdc-dialog-subhead-color: #{colors.$nc-core-base-black-opacity-80};
  --mdc-dialog-subhead-size: #{map.get(typo.$nc-subtitle-1, 'font-size')};
  --mdc-dialog-subhead-weight: #{map.get(typo.$nc-subtitle-1, 'font-weight')};
  --mdc-dialog-subhead-line-height: 0;
  --mat-dialog-headline-padding: 0;
}

mat-dialog-content {
  --mat-dialog-with-actions-content-padding: 0;
  --mdc-dialog-supporting-text-color: #{colors.$nc-core-base-black-opacity-80};
}

mat-dialog-actions {
  --mat-dialog-actions-padding: 0;
}
