@use '@angular/material' as mat;

@font-face {
  font-family: Roobert;
  src: local('Roobert-Regular'), url('/assets/fonts/Roobert-Regular.woff');
  font-weight: 400;
}

@font-face {
  font-family: Roobert;
  src: local('Roobert-Medium'), url('/assets/fonts/Roobert-Medium.woff');
  font-weight: 500;
}

@font-face {
  font-family: Roobert;
  src: local('Roobert-Bold'), url('/assets/fonts/Roobert-Bold.woff');
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: local('Roboto-Regular'), url('/assets/fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: local('Roboto-Bold'), url('/assets/fonts/Roboto-Bold.ttf');
  font-weight: 700;
}

html,
body {
  font-family: Roobert, Roboto, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  color: var(--nc-core-base-black-opacity-80);
}

$default-font-family: 'Roobert';
$default-line-height: 135%;
$body2-font-size: 14px;
$body2-font-weight: 400;

:root {
  --mdc-typography-body2-font-family: #{$default-font-family};
  --mdc-typography-body2-font-size: #{$body2-font-size};
  --mdc-typography-body2-font-weight: #{$body2-font-weight};
  --mdc-typography-body2-line-height: #{$default-line-height};
}

$nc-headline-1: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 400,
  $font-size: 64px,
  $line-height: $default-line-height,
);
$nc-headline-2: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 400,
  $font-size: 56px,
  $line-height: $default-line-height,
);
$nc-headline-3: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 500,
  $font-size: 48px,
  $line-height: $default-line-height,
);
$nc-headline-4: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 400,
  $font-size: 40px,
  $line-height: $default-line-height,
);
$nc-headline-5: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 500,
  $font-size: 32px,
  $line-height: $default-line-height,
);
$nc-headline-6: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 500,
  $font-size: 24px,
  $line-height: $default-line-height,
);
$nc-subtitle-1: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 500,
  $font-size: 20px,
  $line-height: $default-line-height,
);
$nc-body-1: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 400,
  $font-size: 16px,
  $line-height: $default-line-height,
);
$nc-subtitle-2: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 700,
  $font-size: 16px,
  $line-height: $default-line-height,
);
$nc-body-2: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: $body2-font-weight,
  $font-size: $body2-font-size,
  $line-height: $default-line-height,
);
$nc-caption: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 400,
  $font-size: 12px,
  $line-height: $default-line-height,
);
$nc-button: mat.define-typography-level(
  $font-family: $default-font-family,
  $font-weight: 700,
  $font-size: 16px,
  $line-height: 100%,
);
$nc-default-typography: mat.define-typography-config(
  $headline-1: $nc-headline-1,
  $headline-2: $nc-headline-2,
  $headline-3: $nc-headline-3,
  $headline-4: $nc-headline-4,
  $headline-5: $nc-headline-5,
  $headline-6: $nc-headline-6,
  $body-1: $nc-body-1,
  $body-2: $nc-body-2,
  $subtitle-1: $nc-subtitle-1,
  $subtitle-2: $nc-subtitle-2,
  $button: $nc-button,
  $caption: $nc-caption,
);
