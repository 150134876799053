.mat-mdc-form-field-input-control::-webkit-datetime-edit {
  @apply mb-0 leading-6 #{!important};
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 62.5rem white inset;
}

::-webkit-calendar-picker-indicator {
  display: none;
}

::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-year-field {
  color: inherit;
}

::-webkit-datetime-edit-month-field:focus,
::-webkit-datetime-edit-day-field:focus,
::-webkit-datetime-edit-year-field:focus {
  @apply bg-nc-blue-500 text-nc-white;

  border-radius: 0.25rem;
}
