@use 'sass:map';
@use '@angular/material' as mat;
@use 'components/auto-complete' as autoComplete;
@use 'components/button-toggle' as buttonToggle;
@use 'components/checkbox' as checkbox;
@use 'components/dialog' as dialog;
@use 'components/expansion-panel' as expansionPanel;
@use 'components/formfield' as formfield;
@use 'components/radio' as radio;
@use 'components/select' as select;
@use 'components/snackbar' as snackbar;
@use 'components/table' as table;
@use './palettes' as palettes;
@use './colors' as colors;
@use './typography' as typo;
@use './datepicker' as datepicker;
@tailwind base;
@tailwind components;
@tailwind utilities;
@include mat.core;

/** DEFAULT THEME **/
$nc-default-theme: mat.define-light-theme(
  (
    color: (
      primary: palettes.$nc-default-primary-palette,
      accent: palettes.$nc-default-accent-palette,
      warn: palettes.$nc-default-warn-palette,
    ),
    typography: typo.$nc-default-typography,
    density: 0,
  )
);

/** COMPONENT THEME **/
$nc-comp-theme: mat.define-light-theme(
  (
    color: (
      primary: palettes.$nc-comp-primary-palette,
      accent: palettes.$nc-comp-accent-palette,
      warn: palettes.$nc-comp-warn-palette,
    ),
    typography: typo.$nc-default-typography,
    density: 0,
  )
);

/** EMITTING STYLES **/
@include mat.core-theme($nc-default-theme);
@include mat.all-component-themes($nc-comp-theme);
@include mat.typography-hierarchy(typo.$nc-default-typography);
@include checkbox.nc-checkbox-theme;
@include formfield.nc-formfield-theme;
@include radio.nc-radio-theme;
@include select.nc-select-theme;
@include table.nc-table-theme;

$default-bg-color: colors.$nc-core-gray-50;

@media print {
  ::-webkit-scrollbar {
    display: none;
  }
}

html {
  --mat-sidenav-container-background-color: #{$default-bg-color};
  --mat-sidenav-content-background-color: #{$default-bg-color};
  --mat-sidenav-container-text-color: #{colors.$dark-primary-text};
}

body {
  background-color: $default-bg-color;
}

// Get rid of weird vertical border in select component - compatibility bug between Tailwind and Angular Material
.mdc-notched-outline__notch {
  border-right: none !important;
}

mat-form-field {
  --mat-form-field-subscript-text-line-height: 135%;
}

mat-form-field mat-hint {
  @apply text-nc-gray-800;
}

// Display icons in menu buttons inline with text
.mat-mdc-menu-content button .mat-mdc-menu-item-text {
  @apply flex items-center;
}

mat-tooltip-component {
  --mdc-plain-tooltip-container-color: #{colors.$nc-core-gray-600};
  --mdc-plain-tooltip-supporting-text-color: #{colors.$light-primary-text};
}

mat-divider {
  --mat-divider-color: #{colors.$nc-core-gray-100};
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  --mat-dialog-container-max-width: 100vw;
  --mat-dialog-container-small-max-width: 100vw;
}
